import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `rebeccapurple`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div style={{ display: "flex", alignItems: "flex-end", columnGap: 20 }}>
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}{" "}
          </Link>
        </h1>
        <a
          href="https://www.facebook.com/Elektrotherapie-100925802239798"
          target="_blank"
        >
          <span
            className="material-icons"
            style={{ color: "white", fontSize: 32 }}
          >
            facebook
          </span>
        </a>
        <a href="https://twitter.com/Elektrotherapi1" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 239 239"
          >
            <path
              id="Subtraction_1"
              data-name="Subtraction 1"
              d="M120,239.5a120.38,120.38,0,0,1-24.083-2.428,118.845,118.845,0,0,1-42.73-17.981,119.849,119.849,0,0,1-43.3-52.577,118.9,118.9,0,0,1-6.963-22.431,120.666,120.666,0,0,1,0-48.167,118.845,118.845,0,0,1,17.981-42.73,119.847,119.847,0,0,1,52.576-43.3A118.9,118.9,0,0,1,95.916,2.928a120.666,120.666,0,0,1,48.167,0,118.846,118.846,0,0,1,42.73,17.981,119.848,119.848,0,0,1,43.3,52.576,118.894,118.894,0,0,1,6.963,22.431,120.663,120.663,0,0,1,0,48.167,118.846,118.846,0,0,1-17.981,42.73,119.85,119.85,0,0,1-52.577,43.3,118.9,118.9,0,0,1-22.431,6.963A120.381,120.381,0,0,1,120,239.5ZM37.98,171.417v.013c14.222,8.353,28.846,13.412,43.465,15.035h20.417c46.872-5.309,83.453-47.3,83.28-95.6,0-1.44-.032-2.9-.094-4.339a69.4,69.4,0,0,0,16.781-17.38l0-.026a67.193,67.193,0,0,1-19.3,5.3A33.71,33.71,0,0,0,197.277,55.84a66.567,66.567,0,0,1-21.335,8.15c-6.167-6.113-13.425-9.81-20.988-10.692h-7.268A35.005,35.005,0,0,0,123.08,68.536a31.222,31.222,0,0,0-4.433,26.138,95.993,95.993,0,0,1-20.281-3.214A95.178,95.178,0,0,1,79.737,84.23,95.5,95.5,0,0,1,63.243,73.442,95.578,95.578,0,0,1,49.367,59.555c-10.091,17.158-2.457,36.629,10.4,44.829a33.438,33.438,0,0,1-15.223-4.207c0,.141,0,.284,0,.422A33.745,33.745,0,0,0,71.513,133.56a37.768,37.768,0,0,1-9.039,1.145,34,34,0,0,1-6.184-.569,33.832,33.832,0,0,0,31.4,23.373A71.288,71.288,0,0,1,67.66,168.283a66.861,66.861,0,0,1-21.687,3.6A70.115,70.115,0,0,1,37.98,171.417Z"
              transform="translate(-0.5 -0.5)"
              fill="#fff"
            />
          </svg>
        </a>
        <p style={{ opacity: 0.5, fontSize: 22, margin: 0, color: "white" }}>
          tdcsrosenheim.de
        </p>
      </div>

      <h1></h1>

      <nav className="nav">
        <ul
          className="nav__list"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            listStyle: "none",
            textDecoration: "none",
            marginLeft: 0,
            columnGap: 20,
            alignItems: "center",
            // justifyContent: "space-between",
          }}
        >
          <Link activeStyle={{ color: "#FFC107" }} className="nav__item" to="/">
            <span className="material-icons">home</span>
          </Link>

          <Link
            activeStyle={{ color: "#FFC107" }}
            className="nav__item"
            to="/krankheitsbilder/"
          >
            Krankheitsbilder
          </Link>

          <Link
            activeStyle={{ color: "#FFC107" }}
            className="nav__item"
            to="/methoden/"
          >
            Methoden
          </Link>

          <Link
            activeStyle={{ color: "#FFC107" }}
            className="nav__item"
            to="/studien/"
          >
            Studien
          </Link>

          <Link
            activeStyle={{ color: "#FFC107" }}
            className="nav__item"
            to="/artikeln/"
          >
            Artikeln
          </Link>

          <Link
            activeStyle={{ color: "#FFC107" }}
            className="nav__item"
            to="/impressum/"
          >
            Impressum
          </Link>

          <Link
            activeStyle={{ color: "#FFC107" }}
            className="nav__item"
            to="/datenschutz/"
          >
            Datenschutz
          </Link>

          <Link
            activeStyle={{ color: "#FFC107" }}
            className="nav__item"
            to="/kontact/"
          >
            Kontact
          </Link>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
